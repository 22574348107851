import React from 'react';
import {Notification} from "../../StyleComponents/pagesStyle"
import {IconButton} from "../../StyleComponents/styles"

const Notte = ({close,title,strong,text,color,background  }) => {
    return (
      <Notification role="alert"
      background={background}
      color={color}
      >
          <h5>{title}</h5>
        <IconButton
        type="button" className="deleteClose" onClick={close} data-dismiss="alert" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </IconButton>
        <p>{strong?<strong>{strong}<br/></strong>:""} {text}</p>
      </Notification>
    );
  };

  export default Notte