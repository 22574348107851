import React, { useState, useRef } from "react"
import Layout from "../../../components/Layout"
import SEO from "../../../components/SEO/SEO"
import NavBar from "../../../components/NavBar/NavBar"
import SeeAlso from "../../../components/SeeAlso/SeeAlso"
import AboutImg from "../../../components/Image/AboutImg"
import Notte from "../../../componentForPages/Notification/Notification"

import { lbsTokg, inTocm } from "../../../components/Script/converter"

import {
  Title,
  Select,
  Input,
  Field,
  Control,
  StaticButton,
} from "../../../StyleComponents/styles"
import { FlexDiv, Table } from "../../../StyleComponents/pagesStyle"

const navRoadArray = [
  { name: "Calculator", link: "/calculator/" },
  { name: "BMI Calculator", link: "/bmi-calculator/" },
]
const seeAlsoArray = ["/body-mass-fat-calculator", "/rep-max-calculator"]

function BMICalculator(props) {
  let [TextExpainer, setTextExpainer] = useState({})
  let [resultBmi, setresultBmi] = useState("")

  let Weight = useRef("")
  let Height = useRef("")

  let [weightSelect, setweightSelect] = useState("Kg")
  let [heightSelect, setHeightSelect] = useState("Cm")

  function selectHeightUnit(e) {
    setHeightSelect(e.target.value)
    calculateBMI()
  }

  function selectWeightUnit(e) {
    setweightSelect(e.target.value)
    calculateBMI()
  }

  const underweight =
    "Eat more of five small meals during the day or three large meals. Add extras to your dishes for more calories."
  const obesity =
    "Obesity is caused by receiving more calories, and it is a huge problem for many people. Your health care provider can help you better understand the causes of obesity and how to lose weight."
  function calculateBMI() {
    let WeightValue = Weight.current.value
    let HeightValue = Height.current.value
    let a, b, sum
    if (WeightValue !== "") {
      if (HeightValue !== "") {
        if (weightSelect === "Lbs") {
          a = parseInt(lbsTokg(WeightValue), 0)
        } else {
          a = parseInt(WeightValue, 0)
        }
        if (heightSelect === "In") {
          b = parseInt(inTocm(HeightValue), 0)
        } else {
          b = parseInt(HeightValue, 0)
        }
        sum = (a / (b * b)) * 10000
        setresultBmi(sum.toFixed(1))
        message(sum)
      }
    }
  }
  function message(sum) {
    let BMIResult = sum
    let strongBmiStatus = BMIResult
      ? `Your BMI is : ${BMIResult.toFixed(3)}`
      : ""
    if (sum < 16) {
      setTextExpainer({
        background: "#209cee",
        color: "#fff",
        title: "Very Severe underweight",
        text: underweight,
        strong: strongBmiStatus,
      })
    }
    if (sum > 15 && sum < 19) {
      setTextExpainer({
        background: "#209cee",
        color: "#fff",
        title: "Severe underweight",
        text: underweight,
        strong: strongBmiStatus,
      })
    }
    if (sum > 18 && sum < 18.6) {
      setTextExpainer({
        background: "#209cee",
        color: "#fff",
        title: "Underweight",
        text: underweight,
        strong: strongBmiStatus,
      })
    }
    if (sum > 18.5 && sum < 25) {
      setTextExpainer({
        background: "#48c774",
        color: "#fff",
        title: "Normal (healthy weight)",
        text:
          "Keep your body healthy. Staying hydrated and eat a balanced diet is equally important.",
        strong: strongBmiStatus,
      })
    }
    if (sum > 24.9 && sum < 29.9) {
      setTextExpainer({
        background: "#ffdd57",
        color: "black",
        title: "Over-weight",
        text:
          "Make Diet. you need loss weight of about one pound a week is the safest way to lose weight. An overweight BMI is generally associated with a higher risk of chronic diseases. Your health care provider can help you better understand the reasons you’re overweight.",
        strong: strongBmiStatus,
      })
    }
    if (sum > 29.9 && sum < 36) {
      setTextExpainer({
        background: "#f14668",
        color: "white",
        title: "Obesity (class 1)",
        text: obesity,
        strong: strongBmiStatus,
      })
    }
    if (sum > 35 && sum < 41) {
      setTextExpainer({
        background: "#f14668",
        color: "white",
        title: "Obesity (class 2)",
        text: obesity,
        strong: strongBmiStatus,
      })
    }
    if (sum > 40) {
      setTextExpainer({
        background: "#f14668",
        color: "white",
        title: "Morbid obesity",
        text: obesity,
        strong: strongBmiStatus,
      })
    }
  }
  function delet(e) {
    setTextExpainer("")
  }

  return (
    <Layout location={props.location}>
      <SEO
        title="BMI Calculator - Body mass index Calculator"
        description="Body Mass Index (BMI) Calculator, simply calculates your body mass index and your body mass fat percentage."
        keywords={[
          "BMI,Bmi,bmi,Calculate Bmi, Bmi Calculator, What is my Bmi, MY Bmi, How Bmi, Body Mass Index ,Body Mass Fat Percentage, Body Mass Fat Percentage calculator, calculate Body Mass Fat, my fat Percentage.",
        ]}
      />
      <NavBar listPages={navRoadArray} />
      <div className="container">
        <Title>BMI Calculator</Title>
        <FlexDiv maxWidth="500px">
          <Field>
            <Control>
              <StaticButton>Weight</StaticButton>
            </Control>
            <Control className="expandedWidth">
              <Input
                radius="0"
                id="number1"
                ref={Weight}
                onChange={calculateBMI}
                type="number"
                placeholder="Your Weight"
              />
            </Control>
            <Control>
              <Select>
                <select
                  className="staticSelect"
                  aria-label="Weight-one"
                  value={weightSelect}
                  onChange={selectWeightUnit}
                  onBlur={selectWeightUnit}
                >
                  <option value="Kg">Kg</option>
                  <option value="Lbs">Lbs</option>
                </select>
              </Select>
            </Control>
          </Field>

          <Field>
            <Control>
              <StaticButton>Height</StaticButton>
            </Control>
            <Control className="expandedWidth">
              <Input
                radius="0"
                id="number2"
                ref={Height}
                onChange={calculateBMI}
                type="number"
                placeholder="Your Height"
              />
            </Control>
            <Control>
              <Select>
                <select
                  className="staticSelect"
                  aria-label="Height-one"
                  value={heightSelect}
                  onChange={selectHeightUnit}
                  onBlur={selectHeightUnit}
                >
                  <option value="Cm">Cm</option>
                  <option value="In">In</option>
                </select>
              </Select>
            </Control>
          </Field>

          <Field>
            <Control>
              <StaticButton>Your BMI is</StaticButton>
            </Control>
            <Control className="expandedWidth">
              <Input
                radius="0"
                id="result"
                type="text"
                value={resultBmi}
                name="result"
                placeholder="Your BMI is"
                disabled
              />
            </Control>
            <Control>
              <StaticButton
                bottomLeftRadius="0"
                TopLeftRadius="0"
                bottomRightRadius="4px"
                topRightRadius="4px"
              >
                kg/m<sup>2</sup>
              </StaticButton>
            </Control>
          </Field>
        </FlexDiv>
        <br />

        <div>
          {TextExpainer.strong && (
            <Notte
              title={TextExpainer.title}
              close={delet}
              text={TextExpainer.text}
              strong={TextExpainer.strong}
              color={TextExpainer.color}
              background={TextExpainer.background}
            />
          )}
          {/* {TextExpainer} */}
        </div>
        <br />
        <p>
          Body mass index BMI is a measure of your general health and is based
          on your height and weight ratio.
        </p>
        <h4>BMI Level Indicator</h4>
        <FlexDiv maxWidth="600px">
          <Table>
            <thead>
              <tr>
                <th>
                  <abbr title="Position"></abbr>
                </th>
                <th>Category</th>
                <th>From</th>
                <th>To</th>
                <th>Unit</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>1</th>
                <td className="has-text-link">Very severely underweight</td>
                <td>...</td>
                <td>15.0</td>
                <td>
                  kg/m<sup>2</sup>
                </td>
              </tr>
              <tr>
                <th>2</th>
                <td className="has-text-link">Severely underweight</td>
                <td>15.0</td>
                <td>16.0</td>
                <td>
                  kg/m<sup>2</sup>
                </td>
              </tr>
              <tr>
                <th>3</th>
                <td className="has-text-link">Underweight</td>
                <td>16.0</td>
                <td>18.5</td>
                <td>
                  kg/m<sup>2</sup>
                </td>
              </tr>
              <tr>
                <th>4</th>
                <td className="has-text-success">Normal (healthy weight)</td>
                <td>18.5</td>
                <td>24.9</td>
                <td>
                  kg/m<sup>2</sup>
                </td>
              </tr>
              <tr>
                <th>5</th>
                <td className="redditColor">Overweight</td>
                <td>25</td>
                <td>29.9</td>
                <td>
                  kg/m<sup>2</sup>
                </td>
              </tr>
              <tr>
                <th>6</th>
                <td className="has-text-danger">
                  Obese Class I (Moderately obese)
                </td>
                <td>30</td>
                <td>35</td>
                <td>
                  kg/m<sup>2</sup>
                </td>
              </tr>
              <tr>
                <th>7</th>
                <td className="has-text-danger">
                  Obese Class II (Severely obese)
                </td>
                <td>35</td>
                <td>40</td>
                <td>
                  kg/m<sup>2</sup>
                </td>
              </tr>
              <tr>
                <th>8</th>
                <td className="has-text-danger">
                  Obese Class III (Severely obese)
                </td>
                <td>40</td>
                <td>...</td>
                <td>
                  kg/m<sup>2</sup>
                </td>
              </tr>
            </tbody>
          </Table>
        </FlexDiv>

        <br />

        <br />

        <h4>What Is BMI ?</h4>
        <p>
          Body mass index (BMI) is a value derived from the mass and height of a
          person. is calculated by the body mass divided by the square of the
          body height, and is universally expressed in units of kg/m².
        </p>

        <br />
        <FlexDiv maxWidth="600px">
          <AboutImg filename="bmi.png" alt="BMI chart" />
        </FlexDiv>
        <br />

        <br />
        <h4>Calculate BMI</h4>
        <p>
          BMI calculated by the body mass divided by the square of the body
          height, and is universally expressed in units of kg/m².
        </p>
        <br />
        <SeeAlso seeAlsoList={seeAlsoArray} />
        <br />
      </div>
    </Layout>
  )
}

export default BMICalculator
